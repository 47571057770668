export const TEST_FETCH = 'TEST_FETCH';
export const TEST_FETCH_SUCCESS = 'TEST_FETCH_SUCCESS';

export const ANTIVIRUS_CHECK = 'ANTIVIRUS_CHECK';
export const ANTIVIRUS_CHECK_SUCCESS = 'ANTIVIRUS_CHECK_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SKILL_DATA_FETCH = 'SKILL_DATA_FETCH';
export const SKILL_DATA_FETCH_SUCCESS = 'SKILL_DATA_FETCH_SUCCESS';
export const SKILL_DATA_FETCH_FAILED = 'SKILL_DATA_FETCH_FAILED';
export const ADD_NEW_SKILL = 'ADD_NEW_SKILL';
export const ADD_NEW_SKILL_SUCCESS = 'ADD_NEW_SKILL_SUCCESS';
export const ADD_NEW_SKILL_FAILURE = 'ADD_NEW_SKILL_FAILURE';
export const UPDATE_SKILL_INFO = 'UPDATE_SKILL_INFO';
export const UPDATE_SKILL_INFO_SUCCESS = 'UPDATE_SKILL_INFO_SUCCESS';
export const UPDATE_SKILL_INFO_FAILURE = 'UPDATE_SKILL_INFO_FAILURE';
export const DELETE_SKILL = 'DELETE_SKILL';
export const DELETE_SKILL_STARTED = 'DELETE_SKILL_STARTED';
export const DELETE_SKILL_SUCCESS = 'DELETE_SKILL_SUCCESS';
export const DELETE_SKILL_FAILED = 'DELETE_SKILL_FAILED';
export const DELETE_SKILL_RESET = 'DELETE_SKILL_RESET';
export const RESET_MAPPED_SKILL_LP = 'RESET_MAPPED_SKILL_LP';

export const CLIENT_DATA_FETCH = 'CLIENT_DATA_FETCH';
export const CLIENT_DATA_FETCH_SUCCESS = 'CLIENT_DATA_FETCH_SUCCESS';
export const CLIENT_DATA_FETCH_FAILED = 'CLIENT_DATA_FETCH_FAILED';
export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const ADD_NEW_CLIENT_SUCCESS = 'ADD_NEW_CLIENT_SUCCESS';
export const ADD_NEW_CLIENT_FAILURE = 'ADD_NEW_CLIENT_FAILURE';
export const UPDATE_CLIENT_INFO = 'UPDATE_CLIENT_INFO';
export const UPDATE_CLIENT_INFO_SUCCESS = 'UPDATE_CLIENT_INFO_SUCCESS';
export const UPDATE_CLIENT_INFO_FAILURE = 'UPDATE_CLIENT_INFO_FAILURE';

export const PORTFOLIO_DATA_FETCH = 'PORTFOLIO_DATA_FETCH';
export const PORTFOLIO_DATA_FETCH_SUCCESS = 'PORTFOLIO_DATA_FETCH_SUCCESS';
export const PORTFOLIO_DATA_FETCH_FAILED = 'PORTFOLIO_DATA_FETCH_FAILED';
export const ADD_NEW_PORTFOLIO = 'ADD_NEW_PORTFOLIO';
export const ADD_NEW_PORTFOLIO_SUCCESS = 'ADD_NEW_PORTFOLIO_SUCCESS';
export const ADD_NEW_PORTFOLIO_FAILURE = 'ADD_NEW_PORTFOLIO_FAILURE';
export const DELETE_PORTFOLIO = 'DELETE_PORTFOLIO';
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS';
export const DELETE_PORTFOLIO_FAILURE = 'DELETE_PORTFOLIO_FILURE';
export const UPDATE_PORTFOLIO = 'UPDATE_PORTFOLIO';
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS';
export const UPDATE_PORTFOLIO_FAILURE = 'UPDATE_PORTFOLIO_FAILURE';
export const GET_PORTFOLIO_OFFERING_LIST = 'GET_PORTFOLIO_OFFERING_LIST';
export const GET_PORTFOLIO_OFFERING_LIST_SUCCESS = 'GET_PORTFOLIO_OFFERING_LIST_SUCCESS';
export const GET_PORTFOLIO_OFFERING_LIST_FAILURE = 'GET_PORTFOLIO_OFFERING_LIST_FAILURE';
export const MARK_FOR_DELETION = 'MARK_FOR_DELETION';
export const MARK_FOR_DELETION_SUCCESS = 'MARK_FOR_DELETION_SUCCESS';
export const MARK_FOR_DELETION_FAILURE = 'MARK_FOR_DELETION_FAILURE';
export const CLEAR_TRANSACTION_ID = 'CLEAR_TRANSACTION_ID';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const DELETE_MARKED_USER = 'DELETE_MARKED_USER';
export const DELETE_MARKED_USER_SUCCESS = 'DELETE_MARKED_USER_SUCCESS';
export const DELETE_MARKED_USER_FAILURE = 'DELETE_MARKED_USER_FAILURE';

// PLATFORM LEVEL USER MANAGEMENT

export const USERS_FETCH = 'USERS_FETCH';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_ALERTBAR = 'CLEAR_ALERTBAR';
export const SET_SNACKBAR = 'SET_SNACKBAR';

// PLATFORM LEVEL USER MANAGEMENT

export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';
export const FETCH_USER_DETAILS_FAILED = 'FETCH_USER_DETAILS_FAILED';
export const FETCH_CLIENTID = 'FETCH_CLIENTID';
export const FETCH_CLIENTID_SUCCESS = 'FETCH_CLIENTID_SUCCESS';
export const FETCH_CLIENTID_FAILED = 'FETCH_CLIENTID_FAILED';
export const SET_USER_DETAILS_SUCCESS = 'SET_USER_DETAILS_SUCCESS';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const UPDATE_USER_DETAILS_STORE = 'UPDATE_USER_DETAILS_STORE';
export const SET_PLATFORM_DETAILS_LOADING = 'SET_PLATFORM_DETAILS_LOADING';
export const SAVE_USER_DETAILS = 'SAVE_USER_DETAILS';
export const TOGGLE_USER_DETAIL_ALERTBAR = 'TOGGLE_USER_DETAIL_ALERTBAR';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

// FILE UPLOAD VALIDATION
export const FILE_VALIDATE = 'FILE_VALIDATE';
export const FILE_VALIDATE_SUCCESS = 'FILE_VALIDATE_SUCCESS';
export const FILE_VALIDATE_FAILURE = 'FILE_VALIDATE_SUCCESS';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAMS_STARTED = 'GET_PROGRAMS_STARTED';
export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS';
export const GET_PROGRAMS_FAILURE = 'GET_PROGRAMS_FAILURE';
export const GET_INITIAL_PROGRAMS_STARTED = 'GET_INITIAL_PROGRAMS_STARTED';
export const GET_FILTERED_PROGRAMS = 'GET_FILTERED_PROGRAMS';
export const GET_FILTERED_PROGRAMS_SUCCESS = 'GET_FILTERED_PROGRAMS_SUCCESS';
export const SAVE_FORM_VALUES = 'SAVE_FORM_VALUES';
export const REMOVE_FORM_VALUES = 'REMOVE_FORM_VALUES';
