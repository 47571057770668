import React, { useEffect, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';

import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import Drawer from '@mui/material/Drawer';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import _forIn from 'lodash/forIn';
import { useSelector, useDispatch } from 'react-redux';
import {
  saveFilterGroup,
  updateFilterGroup,
  deleteFilterGroups,
} from '../../containers/BasePage/apis';
import { getErrorMessage, getErrorType } from '../../helpers/apiHelper';
import NoResultsIllustration from '../../assets/img/no-program.svg';
import LpFiltersDrawer from '../LpFiltersDrawer';
import {
  MODERATE_DARK_GREY,
  LIGHT_MEDIUM_GREY,
  MCKINSEY_BLUE,
  WHITE,
} from '../../stylesheets/colors';
import {
  GROUPWORK_GROUPS,
  TEST_USERS_CREATION,
  BULK_UNENROLLMENT,
  ASSIGN_COURSE_CONTENT,
  PROGRAM_CREATION,
  STAGE_IN_BUILD,
  STAGE_LAUNCHED,
  STAGE_UNKNOWN,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  AUTO_ENROLLMENT,
  STAGE_DELETED,
  STAGE_REPLICATED,
  SETTINGS,
  BULK_UPDATE_ACCOUNTS,
  WORKSHOP_MANAGEMENT,
  ASSIGNMENT_MANAGEMENT,
  METADATA_MANAGEMENT,
  EDIT_PROGRAM,
  REPLICATION,
  MODIFY_GRADE,
  UPLOAD_GRADE,
  BULK_METADATA_MANAGEMENT,
  SYNC_USER_MANAGEMENT,
  SYNC_SESSION_ENROLLMENTS,
  FORCE_ENROLL_USER,
  ASSIGNMENT_DOWNLOAD,
  SINGLE_SESSION_SYNC,
  CLONE_COURSE,
  INDIVIDUAL_SCORM_REFRESH,
  MULTIPLE_SCORM_REFRESH,
  IMPORT_COURSE,
  PRODUCTION_CHECKLIST,
} from '../../constants';

import LandingPageCard from './LandingPageCard';
import LoadMoreComponent from '../common/LoadMore';
import { REMOVE_FORM_VALUES, SAVE_FORM_VALUES } from '../../store/constants';
import { LearningPlansFiltersValidationSchema } from '../../helpers/validationHelper';
import filterIcon from '../../assets/icons/filter-icon.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: WHITE,
    padding: '4rem',
  },
  allProgramsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    fontFamily: 'Bower',
    fontSize: '3.25rem',
    fontWeight: 'bold',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    gap: '12px',
  },
  createProgramButton: {
    width: '10rem',
    height: '2.5rem',
    backgroundColor: MCKINSEY_BLUE,
    color: WHITE,
  },
  searchRow: {
    display: 'flex',
    marginBottom: '20px',
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    minHeight: '1.5rem',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchInput: {
    flex: 0.8, // ~44rem
    height: '100%',
    borderColor: LIGHT_MEDIUM_GREY,
    fontSize: '14px',
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
    outline: 'none !important',
    outlineOffset: 'none !important',
    borderRadius: '4px',
    color: LIGHT_MEDIUM_GREY,
    margin: 0,
    '& fieldset': {
      border: 0,
    },
  },
  searchButton: {
    width: '5rem',
    borderRadius: '4px',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    // justifyContent: 'space-between',
  },
  cardContainer: {
    width: '300px',
    display: 'flex',
    alignItems: 'start',
    boxSizing: 'content-box',
    marginBottom: '2rem',
    marginRight: '2rem',
  },
  noResultsWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '2rem 0px',
    maxHeight: 'calc(100% - 350px)',
  },
  noResultsImage: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
    width: 'auto',
    maxHeight: '80%',
    paddingTop: '4rem',
  },
  noResultsText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Bower',
    color: MODERATE_DARK_GREY,
    fontSize: '2.5rem',
    fontWeight: 'bold',
    lineHeight: '1.21',
  },
  noResultsTextSubHeading: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: MODERATE_DARK_GREY,
    fontSize: '1.75rem',
    lineHeight: '1.21',
  },
  countLabel: {
    marginRight: '1rem',
  },
  bold: {
    fontWeight: 'bold',
  },
  filterActionButtons: {
    '& > button:nth-child(2)': {
      marginLeft: '1.2rem',
    },
  },
  filterBtn: {
    borderRadius: '4px',
    boxShadow: 'none',
    '& img': {
      marginRight: '0.8rem',
    },
  },
  clearfilterBtn: {
    borderRadius: '4px',
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: 'transparent',
      color: LIGHT_MEDIUM_GREY,
    },
  },
  CreateProgramBtn: {
    borderRadius: '4px',
    boxShadow: 'none',
  },
  searchBoxWrapper: {
    flex: '0.8',
  },
});

// https://stackoverflow.com/a/59226455/1217998
const debouncer = _debounce((f) => f(), 700);

const EmptyPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.noResultsWrapper}>
      <img className={classes.noResultsImage} src={NoResultsIllustration} alt="No results" />
      <Typography className={classes.noResultsText}>Oops!</Typography>
      <Typography className={classes.noResultsTextSubHeading}>
        Could not find the program you were looking for.
      </Typography>
    </div>
  );
};

const stageOptions = [
  { label: 'Unknown', value: STAGE_UNKNOWN },
  { label: 'Replicated', value: STAGE_REPLICATED },
  { label: 'In Build', value: STAGE_IN_BUILD },
  { label: 'QA', value: STAGE_QA },
  { label: 'Launch Prep', value: STAGE_LAUNCH_PREP },
  { label: 'Launched', value: STAGE_LAUNCHED },
  { label: 'Deleted', value: STAGE_DELETED },
];

const programOptions = [
  { label: 'GSR', value: 'is_gsr' },
  { label: 'Skills', value: 'is_skill' },
  { label: 'GO', value: 'is_academy_go' },
];

const formatProgramStatus = (programStatus) => ({
  [GROUPWORK_GROUPS]: 'Last Action: Groupwork groups creation',
  [TEST_USERS_CREATION]: 'Last Action: Test Users creation', // Both prod and ops
  [BULK_UNENROLLMENT]: 'Last Action: Users Unenroll',
  [ASSIGN_COURSE_CONTENT]: 'Last Action: Course Content Assign',
  [PROGRAM_CREATION]: 'Last Action: Program creation',
  [AUTO_ENROLLMENT]: 'Last Action: Users Enroll',
  [SETTINGS]: 'Last Action: Settings',
  [BULK_UPDATE_ACCOUNTS]: 'Last Action: Activate/Deactivate users',
  [WORKSHOP_MANAGEMENT]: 'Last Action: Workshop Management',
  [ASSIGNMENT_MANAGEMENT]: 'Last Action: Assignment Management',
  [ASSIGNMENT_DOWNLOAD]: 'Last Action: Assignment Management',
  [METADATA_MANAGEMENT]: 'Last Action: Metadata Management',
  [BULK_METADATA_MANAGEMENT]: 'Last Action: Metadata Management',
  [SYNC_USER_MANAGEMENT]: 'Last Action: Metadata Management',
  [SYNC_SESSION_ENROLLMENTS]: 'Last Action: Workshop Management',
  [SINGLE_SESSION_SYNC]: 'Last Action: Workshop Management',
  [MODIFY_GRADE]: 'Last Action: Modify grade',
  [UPLOAD_GRADE]: 'Last Action: Upload grade',
  [EDIT_PROGRAM]: 'Last Action: Modify Config ID',
  [REPLICATION]: 'Last Action: Replication',
  [FORCE_ENROLL_USER]: 'Last Action: Force User Enrollment',
  [CLONE_COURSE]: 'Last Action: Copy Component',
  [INDIVIDUAL_SCORM_REFRESH]: 'Last Action: Release Management',
  [MULTIPLE_SCORM_REFRESH]: 'Last Action: Release Management',
  [IMPORT_COURSE]: 'Last Action: Import Component',
  [PRODUCTION_CHECKLIST]: 'Last Action: Production Checklist',
}[programStatus] || '');

const LandingPage = forwardRef(
  (
    {
      cards,
      onSearch,
      onCreateNewProgram,
      showCreateNewProgram,
      onLoadMore,
      hasMore,
      isLoading,
      programCount,
      getFilterGroups,
      loadInitialLPs,
      filterFormData,
      cardWidth,
      cardGap,
      numberofCards,
    },
    ref,
  ) => {
    const { bodyRef, cardRef } = ref;
    const classes = useStyles();
    const history = useHistory();

    const [searchText, setSearchText] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [selectedFilterGroup, setSelectedFilterGroup] = React.useState('');
    const [appliedFilterCount, setAppliedFilterCount] = React.useState(0);
    const [popoverData, setPopoverData] = React.useState({
      type: '',
      message: '',
    });
    const [createdFilterGroup, setCreatedFilterGroup] = React.useState('');
    const dispatch = useDispatch();
    const { selectedFilterValue } = useSelector((state) => state.filterFormData);

    useEffect(() => {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    const setCurrentSearchText = () => {
      setSearchText(get(history, 'location.state.currentSearchText', ''));
      history.replace();
    };

    const openFilterPanel = () => {
      setOpen(true);
    };

    const toggleDrawer = (value) => {
      setOpen(value);
    };

    const updateFilterCount = (payload) => {
      const selectedFilterProperties = [];
      _forIn(payload, (value) => {
        if (typeof value === 'string' || Array.isArray(value)) {
          selectedFilterProperties.push(value?.length > 0);
        } else {
          selectedFilterProperties.push(false);
        }
      });
      setAppliedFilterCount(_filter(selectedFilterProperties, (val) => val === true)?.length);
    };

    const getPayload = (data) => ({
      source_program_ids: _map(data.source_program_ids, (obj) => ({
        id: obj.value,
        name: obj.label,
      })),
      program_ids: _map(data.program_ids, (obj) => ({ id: obj.value, name: obj.label })),
      build_type: data.build_type,
      ...(data?.build_type === 'Source' && {
        source_build_status: data.source_build_status?.sort(),
        replicable: data.replicable,
      }),
      ...(data?.build_type?.length > 0
        && data?.build_type !== 'Source' && {
        source_build_status: data.source_build_status?.sort(),
        replicable: data.replicable,
      }),
      re_usable: data.re_usable,
      elucidat_project_type: data?.elucidat_project_type,
      elucidat_std_date_from:
        data?.elucidat_std_date_from?.length > 0 ? data.elucidat_std_date_from : null,
      elucidat_std_date_to:
        data?.elucidat_std_date_to?.length > 0 ? data.elucidat_std_date_to : null,
      docebo_std_date_from:
        data?.docebo_std_date_from?.length > 0 ? data.docebo_std_date_from : null,
      docebo_std_date_to: data?.docebo_std_date_to?.length > 0 ? data.docebo_std_date_to : null,
      client_ids: _map(data?.client_ids, (obj) => ({
        id: obj.id,
        client_id: obj.value || obj.client_id,
        client_name: obj.client_name,
      })),
      client_setup: data?.client_setup?.sort(),
      language_codes: data?.language_codes?.sort(),
      status: data.status?.sort(),
      closeout_date_from: data?.closeout_date_from?.length > 0 ? data.closeout_date_from : null,
      closeout_date_to: data?.closeout_date_to?.length > 0 ? data.closeout_date_to : null,
      type_of_program: data?.type_of_program,
      portfolio: data?.portfolio?.value,
      offering: data?.offering?.value,
      program: data?.program?.value,
    });

    const onApplyFilter = (filterParams, selectedDropdownValue) => {
      setSearchText('');
      dispatch({
        type: SAVE_FORM_VALUES,
        payload: {
          filterParams,
          selectedFilterValue: selectedDropdownValue || selectedFilterGroup,
        },
      });
    };

    const formik = useFormik({
      initialValues: {
        enableCreate: false,
        enableEdit: false,
        filter_name: '',
        build_type: '',
        replicable: '',
        re_usable: '',
        elucidat_project_type: '',
        elucidat_std_date_from: null,
        elucidat_std_date_to: null,
        docebo_std_date_from: null,
        docebo_std_date_to: null,
        closeout_date_from: null,
        closeout_date_to: null,
        source_build_status: [],
        client_setup: [],
        status: [],
        language_codes: [],
        source_program_ids: [],
        type_of_program: [],
        portfolio: '',
        offering: '',
        program: '',
      },
      validationSchema: LearningPlansFiltersValidationSchema,
      enableReinitialize: true,
      onSubmit: (values, bag) => {
        setCreatedFilterGroup(values?.filter_name);
        const payload = getPayload(values) || {};

        const saveFilter = async () => {
          try {
            const resp = await saveFilterGroup({ name: values?.filter_name, payload });
            if (resp?.data?.success === true) {
              const data = resp?.data?.data;
              bag.setFieldValue('enableCreate', false);
              const dropdownValue = {
                ...data,
                label: data?.name,
                value: data?.payload,
              };
              setSelectedFilterGroup(dropdownValue);
              onApplyFilter(payload, dropdownValue);
              toggleDrawer(false);
            }
          } catch (err) {
            setPopoverData({
              type: getErrorType(err),
              message: getErrorMessage(err),
              data: err?.response?.data?.data,
            });
          }
        };

        const updateFilter = async () => {
          try {
            const resp = await updateFilterGroup(selectedFilterGroup?.id, {
              name: values?.filter_name,
              payload,
            });
            if (resp?.data?.success === true) {
              const data = resp?.data?.data;
              bag.setFieldValue('enableEdit', false);
              const dropdownValue = {
                ...data,
                label: data?.name,
                value: data?.payload,
              };
              setSelectedFilterGroup(dropdownValue);
              onApplyFilter(payload, dropdownValue);
              toggleDrawer(false);
            }
          } catch (err) {
            setPopoverData({
              type: getErrorType(err),
              message: getErrorMessage(err),
            });
          }
        };
        if (values?.enableEdit) {
          updateFilter();
        } else if (values?.enableCreate) {
          saveFilter();
        } else {
          onApplyFilter(payload);
          toggleDrawer(false);
        }
      },
    });

    const appliedCount = (count) => `(${count})`;

    const {
      handleSubmit,
      handleChange,
      values,
      setValues,
      resetForm,
      setFieldValue,
      errors,
      touched,
    } = formik;

    const setPayloadIntoForm = (data) => {
      setValues({
        ...data,
        ...(data?.source_program_ids?.length > 0 && {
          source_program_ids: _map(data.source_program_ids, (obj) => ({
            value: obj.id,
            label: obj.name,
          })),
        }),
        ...(data?.program_ids?.length > 0 && {
          program_ids: _map(data.program_ids, (obj) => ({ value: obj.id, label: obj.name })),
        }),
        ...(data?.portfolio && {
          portfolio: { value: data?.portfolio, label: data?.portfolio },
        }),
        ...(data?.offering && {
          offering: { value: data?.offering, label: data?.offering },
        }),
        ...(data?.program && {
          program: { value: data?.program, label: data?.program },
        }),
      });
    };

    useEffect(() => {
      if (filterFormData && Object.keys(filterFormData).length > 0) {
        setPayloadIntoForm(filterFormData);
        updateFilterCount(filterFormData);
      } else {
        resetForm();
        setAppliedFilterCount(0);
      }
      if (Object.keys(selectedFilterValue).length > 0) {
        setSelectedFilterGroup(selectedFilterValue);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFormData, selectedFilterValue, open]);

    useEffect(() => {
      setCurrentSearchText();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
      () => debouncer(() => {
        onSearch(searchText || '');
      }),
      [onSearch, searchText],
    );

    const onSearchInner = useCallback(
      (event) => {
        const newText = event.target.value;
        setSearchText(newText);
      },
      [setSearchText],
    );

    const clearFormStorevalues = useCallback(() => {
      dispatch({
        type: REMOVE_FORM_VALUES,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteFilterGroup = useCallback(async () => {
      try {
        setPopoverData({});
        const resp = await deleteFilterGroups(selectedFilterGroup?.id);
        if (resp?.success === true) {
          setPopoverData({
            type: 'delete_success',
            message: '',
          });
          resetForm();
          clearFormStorevalues();
        }
      } catch (err) {
        setPopoverData({
          type: getErrorType(err),
          message: getErrorMessage(err),
        });
      }
    }, [selectedFilterGroup, resetForm, clearFormStorevalues]);

    const cardsDom = cards.map((card, index) => {
      const isLastInRow = (index + 1) % numberofCards === 0;
      return (
        <div
          ref={cardRef}
          className={classes.cardContainer}
          key={card.program_id}
          style={{ width: cardWidth, marginRight: isLastInRow ? '0' : cardGap }}
        >
          <LandingPageCard
            updatedBy={`${card.lastActivityFirstName} ${card.lastActivityLastName}`}
            configId={card.config_id}
            status={formatProgramStatus(card.status)}
            stage={card.stage}
            docebo={card.docebo && !card.replicated}
            programId={card.program_id}
            currentSearchText={searchText}
            isAcademyGo={card.is_academy_go}
            isGsr={card.is_gsr}
            isSkills={card.is_skill}
            cardData={card}
            isLocked={card.is_locked}
          />
        </div>
      );
    });

    const isEmpty = get(cards, 'length') === 0 && !isLoading && !hasMore;

    const clearAllFilters = () => {
      loadInitialLPs();
      resetForm();
      setAppliedFilterCount(0);
      setSelectedFilterGroup({});
      clearFormStorevalues();
    };

    return (
      <>
        <div className={classes.wrapper}>
          <div className={classes.searchRow}>
            <div className={classes.tabWrapper}>
              <TextField
                className={classes.searchInput}
                name={'lp_search'}
                type={'text'}
                variant="outlined"
                margin="dense"
                placeholder="Search for learning plans"
                value={searchText}
                onChange={onSearchInner}
                disabled={appliedFilterCount !== 0}
                InputProps={{
                  endAdornment: <SearchIcon htmlColor="#cccccc" />,
                }}
              />
              {showCreateNewProgram ? (
                <Button
                  className={classes.CreateProgramBtn}
                  color="primary"
                  variant="contained"
                  onClick={onCreateNewProgram}
                >
                  <AddIcon style={{ marginRight: '0.5rem' }} />
                  {' '}
                  Create Program
                </Button>
              ) : (
                <></>
              )}
            </div>
            <div className={`${classes.searchWrapper} ${classes.filterActionButtons}`}>
              <Button
                color="primary"
                className={classes.clearfilterBtn}
                variant="outlined"
                disabled={appliedFilterCount === 0}
                onClick={clearAllFilters}
              >
                Clear
              </Button>
              <Button
                className={classes.filterBtn}
                color="primary"
                variant="contained"
                onClick={openFilterPanel}
              >
                {<img src={filterIcon} alt="filter-icon" width={14} height={14} />}
                {`Filters ${appliedFilterCount > 0 ? appliedCount(appliedFilterCount) : ''}`}
              </Button>
            </div>
          </div>
          <div ref={bodyRef} className={classes.bodyWrapper}>
            {!isEmpty ? cardsDom : <EmptyPage />}
          </div>
          {!isEmpty && (
            <LoadMoreComponent
              totalItems={programCount}
              onLoadMore={onLoadMore}
              loadMoreText="Programs"
              itemsShown={cards.length}
              hasMore={hasMore}
              isLoading={isLoading}
            />
          )}
        </div>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          className={classes.drawerWrapper}
        >
          <LpFiltersDrawer
            closeFilterDrawer={() => toggleDrawer(false)}
            values={values}
            setFieldValue={setFieldValue}
            resetForm={resetForm}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            stageOptions={stageOptions}
            getFilterGroups={getFilterGroups}
            popoverData={popoverData}
            setPopoverData={setPopoverData}
            errors={errors}
            touched={touched}
            setValues={setValues}
            selectedFilterGroup={selectedFilterGroup}
            setSelectedFilterGroup={setSelectedFilterGroup}
            deleteFilterGroup={deleteFilterGroup}
            createdFilterGroup={createdFilterGroup}
            setCreatedFilterGroup={setCreatedFilterGroup}
            clearAllFilters={clearAllFilters}
            setPayloadIntoForm={setPayloadIntoForm}
            programOptions={programOptions}
          />
        </Drawer>
      </>
    );
  },
);

LandingPage.displayName = 'LandingPage';

LandingPage.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      config_id: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      stage: PropTypes.string.isRequired,
      program_id: PropTypes.number.isRequired,
      docebo: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreateNewProgram: PropTypes.func.isRequired,
  showCreateNewProgram: PropTypes.bool.isRequired,
  programCount: PropTypes.number.isRequired,
  getFilterGroups: PropTypes.func.isRequired,
  loadInitialLPs: PropTypes.func.isRequired,
  filterFormData: PropTypes.object.isRequired,
  cardWidth: PropTypes.string.isRequired,
  cardGap: PropTypes.string.isRequired,
  numberofCards: PropTypes.number.isRequired,
};

export default LandingPage;
