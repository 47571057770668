import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const RouteWithTitle = ({ title, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Component {...props} />
      </>
    )}
  />
);

RouteWithTitle.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default RouteWithTitle;
