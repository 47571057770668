import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography/Typography';
import Stack from '@mui/material/Stack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormattedTypography from '../common/FormattedTypography';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import {
  COMPLETED_WITH_ERRORS,
} from '../../constants';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  bold: {
    fontWeight: 900,
  },
  bodyText: {
    textAlign: 'left',
    padding: '0px 12rem',
  },
  reportSection: {
    margin: '2rem 1rem',
  },
  stackItem: {
    margin: '1rem 1.5rem',
  },
  sufix: {
    color: DARK_MEDIUM_GREY,
    paddingLeft: '0.5rem',
  },
  container: {
    justifyContent: 'flex-start',
  },
});

const ProgressReportModal = ({
  progressData, action,
}) => {
  const modalText = {
    Mark: {
      heading: 'Users Marked for Deletion',
      successful_rows_text: 'users successfully marked for deletion',
      failed_rows_text: 'user(s) could not be marked for deletion',
    },
    Unmark: {
      heading: 'Users Unmarked for Deletion',
      successful_rows_text: 'users successfully unmarked for deletion',
      failed_rows_text: 'user(s) could not be unmarked for deletion',
    },
    User_creation: {
      heading: 'User Creation',
      successful_rows_text: 'users successfully created.',
      failed_rows_text: 'user(s) could not be created.',
    },
    Delete: {
      heading: 'User Deletion Update',
      successful_rows_text: 'users deleted successfully.',
      failed_rows_text: 'user(s) could not be deleted.',
    },
  };
  const classes = useStyles();
  const { report, status } = progressData;
  return (
    <Container className={classes.bodyText}>
      { status === COMPLETED_WITH_ERRORS ? (
        <Box className={classes.reportSection}>
          <Typography className={classes.bold} variant="subtitle1">
            {modalText[action].heading}
          </Typography>
          <Stack direction="row" className={classes.stackItem} alignItems="center" gap={1}>
            <img
              src={CheckRoundIcon}
              alt="status-icon"
              width={20}
              height={20}
            />
            <FormattedTypography
              containerClassName={classes.container}
              body={`${report.successful_rows}/${report.total_rows}`}
              suffixClassName={classes.sufix}
              suffix={modalText[action].successful_rows_text}
              variant="subtitle1"
            />
          </Stack>
          <Divider variant="fullWidth" light />
          <Stack direction="row" className={classes.stackItem} alignItems="center" gap={1}>
            <img
              src={ErrorRoundIcon}
              alt="status-icon"
              width={20}
              height={20}
            />
            <FormattedTypography
              containerClassName={classes.container}
              body={`${report.failed_rows}/${report.total_rows}`}
              suffixClassName={classes.sufix}
              suffix={modalText[action].failed_rows_text}
              variant="subtitle1"
            />
          </Stack>
        </Box>
      ) : <></>}

    </Container>
  );
};

ProgressReportModal.propTypes = {
  progressData: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
};

export default memo(ProgressReportModal, (() => true));
