import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { isCancel } from 'axios';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Divider } from '@material-ui/core';
import BreadCrumbHOC from '../BreadCrumbHOC';
import {
  AUTO_HIDE_DURATION, QUEUED,
} from '../../constants';
import { MODERATE_LIGHT_GREY, SWITCH_GREY } from '../../stylesheets/colors';
import UserEditCell from './UserEditCell';
import {
  checkSequentialNumber, checkSequentialCharcter,
} from '../../helpers/utils';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import {
  getProgress,
} from '../../store/actions/async/common';
import CustomSnackbar from '../common/CustomSnackbar';
import ErrorModal from '../ErrorModal/ErrorModal';
import AlertReleaseInfo from '../ContentManagement/AlertReleaseInfo';
import EditUserPasswordSection from './EditUserPassworSection';
import { internalServerErrorModalLogic } from '../../containers/common/utils';
import ProgressStatusModal from './ProgressStatusModal';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  promptWrapper: {
    padding: '1.25rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sectionPadding: {
    padding: '2rem 2rem 2rem 2rem',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: '0.625rem',
      fontSize: '1.2rem',
    },
  },
  noTopPadding: {
    paddingTop: '0',
  },
  passwordSectionPadding: {
    padding: '0rem 2rem 2rem 2rem',
    display: 'flex',

    '& svg': {
      marginLeft: '0.625rem',
      fontSize: '1.2rem',
    },
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
    '& th.MuiTableCell-root:first-child > div': {
      marginLeft: '1.5rem',
    },
    '& td.MuiTableCell-root:first-child p': {
      marginLeft: '1.5rem',
    },
    '& th.MuiTableCell-root': {
      lineHeight: '1.92rem',
    },
  },
  userMetadata: {
    borderBottom: `1px solid ${SWITCH_GREY}`,
    paddingBottom: '1.3rem',
    paddingTop: '1rem',
    '& > span': {
      fontWeight: 'bold',
    },
  },
  userStatus: {
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    background: MODERATE_LIGHT_GREY,
    borderRadius: '4px',
    marginLeft: '1rem',
  },
  userContainerBorder: {
    borderTop: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  boldText: {
    fontWeight: 'bold',
  },
  tableContainer: {
    maxHeight: '24vh',
    marginBottom: '3rem',
    flex: 1,
  },
  editIcon: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
  spaceBtw: {
    justifyContent: 'space-between',
  },
  resetBtn: {
    fontSize: '1.125rem',
  },
  doceboEditSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  passwordSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  doceboEditButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '2rem',

    '& > button:nth-child(2)': {
      marginLeft: '1.25rem',
    },
  },
  doceboDetailsEditContainer: {
    marginTop: '1.5rem',
    minHeight: '35vh',
  },
  customAlert: {
    paddingTop: '1rem',
  },
}));

const UserCreation = (props) => {
  const {
    userDetails, clearAlertBar,
    toggleAlertBar, createUser,
    fetchLanguages, fetchClientIds, clearTransactionId,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [data] = useState({
    firstname: '',
    lastname: '',
    username: '',
    language: '',
    client_id: '',
  });
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordError, setPasswordError] = useState(true);
  const [forcePassword, setForcePassword] = useState(false);
  const [sequentialError, setSequentialError] = useState('');
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(false);
  const [resetPasswordSection, setResetPasswordSection] = useState(false);

  const validationSchema = Yup.object({
    firstname: Yup.string().trim().required('This is required field'),
    lastname: Yup.string().trim().required('This is required field'),
    username: Yup.string().email('Invalid email').required('This is required field'),
    language: Yup.string().required('This is required field'),
    client_id: Yup.string().required('This is required field'),
  });

  const {
    values, handleSubmit, errors, touched, resetForm,
    setFieldValue, handleChange,
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (payload) => {
      if (!checkSequentialNumber(password)) {
        setSequentialError('Password can not contain 3 or more consecutive numbers');
      } else if (!checkSequentialCharcter(password)) {
        setSequentialError('Password can not contain 4 or more alphabetically consecutive characters');
      } else {
        const formData = new FormData();
        const payloadData = {
          ...payload,
          ...(!passwordError && { password }),
          ...({ force_password_change: forcePassword ? 'yes' : 'no' }),
        };
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in payloadData) {
          formData.append(key, payloadData[key]);
        }
        createUser(formData);
      }
    },
  });

  const {
    snackbarObj, languageOptions,
    isPlatformDetailsLoading, clientIdOptions, transaction_id,
  } = userDetails;
  const initialAlertBarConfig = {
    labelText: '',
    variant: '',
    open: false,
  };

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        const res = await getProgress(transaction_id);
        const { done } = res.data;
        if (done) {
          clearTransactionId();
          resetForm();
          batchUpdates(() => {
            setProgress(res.data);
            setCreateDisabled(false);
            setPassword('');
            setRetypePassword('');
            setPasswordError(true);
            setForcePassword(false);
          });
        } else {
          batchUpdates(() => {
            setProgress(res.data);
            setCreateDisabled(true);
            if (res.data.status === QUEUED) {
              setOpenStatusModal(true);
            }
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        setShowElement(false);
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (transaction_id) {
      pollProgressApi();
      setShowElement(true);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction_id]);

  useEffect(() => {
    let handler;
    if (progress?.done) {
      const status = progress?.status;
      handler = setTimeout(() => {
        setShowElement(false);
        if (status === 'COMPLETED') {
          history.push('/users');
        }
      }, AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(handler);
    };
  }, [history, progress]);

  useEffect(() => () => {
    toggleAlertBar(initialAlertBarConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchLanguages();
    fetchClientIds();
    resetForm();
    setPassword('');
    setRetypePassword('');
    setPasswordError(true);
    setForcePassword(false);
  }, [fetchLanguages, fetchClientIds, resetForm]);

  // const handleDoceboDetailsSave1 = () => {
  //   const lang = languageOptions.find((el) => (el.value === values.language));
  //   if (!checkSequentialNumber(password)) {
  //     setSequentialError('Password can not contain 3 or more consecutive numbers');
  //   } else if (!checkSequentialCharcter(password)) {
  // eslint-disable-next-line max-len
  //     setSequentialError('Password can not contain 4 or more alphabetically consecutive characters');
  //   } else {
  //     // saveUserDetails({
  //     //   data:
  //     //   {
  //     //     language: lang,
  //     //     primary_experience,
  //     //     ...(!passwordError && { password }),
  //     //     ...(forcePassword && { force_password_change: forcePassword }),
  //     //   },
  //     //   userId,
  //     // });
  //     console.log({
  //       data:
  //       {
  //         language: lang,
  //         ...(!passwordError && { password }),
  //         ...(forcePassword && { force_password_change: forcePassword }),
  //       },
  //     });
  //   }
  // };

  const handleCancel = () => {
    resetForm();
    setPassword('');
    setRetypePassword('');
    setPasswordError(true);
    setForcePassword(false);
    setResetPasswordSection(true);
  };

  const handleComponentChange = (e, value) => {
    setFieldValue(e, value);
  };

  const breadCrumbList = [
    { label: 'User Management', redirectionUrl: '/users', isActive: false },
    { label: 'Create User', redirectionUrl: '', isActive: true },
  ];

  const getUserInfoJsx = () => (
    <Box className={classes.userMetadata}>
      <Typography variant="h4" component="span">Create User</Typography>
    </Box>
  );

  const isButtonDisabled = () => {
    if (!values.firstname.trim() || !values.lastname.trim()
      || !values.username.trim() || !values.language || !values.client_id || !password
      || !retypePassword || passwordError || createDisabled) {
      return true;
    }
    return false;
  };

  const onViewStatus = useCallback(() => {
    batchUpdates(() => {
      setOpenStatusModal(true);
    });
  }, []);

  const getDoceboDetailsJsx = () => (
    <Paper style={{ display: isPlatformDetailsLoading ? 'flex' : '' }} className={classes.doceboDetailsEditContainer}>
      {isPlatformDetailsLoading ? <LoadingCircle />
        : (
          <>
            <Box className={`${classes.sectionPadding} ${classes.spaceBtw}`}>
              <Box className={classes.doceboEditSection}>
                <Typography variant="subtitle1" component="span" className={classes.boldText}>User Details</Typography>
              </Box>
            </Box>
            <Box>
              <Grid container spacing={2} className={`${classes.userContainerBorder} ${classes.sectionPadding}`}>
                <Grid item xs={4}>
                  <UserEditCell
                    label="First Name"
                    name="firstname"
                    value={values.firstname}
                    isEdit
                    fieldType="text"
                    placeHolder="Enter first name"
                    required
                    handleChange={handleChange}
                    hasError={touched.firstname && !!errors.firstname}
                    errorText={touched.firstname && errors.firstname}
                  />
                </Grid>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Last Name"
                    name="lastname"
                    value={values.lastname}
                    isEdit
                    fieldType="text"
                    placeHolder="Enter last name"
                    required
                    handleChange={handleChange}
                    hasError={touched.lastname && !!errors.lastname}
                    errorText={touched.lastname && errors.lastname}
                  />
                </Grid>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Email"
                    name="username"
                    value={values.username}
                    isEdit
                    fieldType="text"
                    placeHolder="Enter Email Address"
                    required
                    handleChange={handleChange}
                    hasError={touched.username && !!errors.username}
                    errorText={touched.username && errors.username}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" className={`${classes.sectionPadding} ${classes.noTopPadding} `}>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Language"
                    name="language"
                    value={values.language}
                    fieldType="select"
                    isEdit
                    options={languageOptions}
                    handleChange={handleComponentChange}
                    placeHolder="Select Language"
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Client ID"
                    name="client_id"
                    value={values.client_id}
                    fieldType="select"
                    isEdit
                    options={clientIdOptions}
                    handleChange={handleComponentChange}
                    placeHolder="Enter Client ID or Client name"
                    required
                  />
                </Grid>
              </Grid>
              <Divider />
              <Box className={`${classes.sectionPadding} ${classes.spaceBtw}`}>
                <Box className={classes.passwordSection}>
                  <Typography variant="subtitle1" component="span" className={classes.boldText}>Password</Typography>
                </Box>
              </Box>
              <Grid container spacing={2} direction="row" className={classes.passwordSectionPadding}>
                <EditUserPasswordSection
                  open
                  setPassword={setPassword}
                  setRetypePassword={setRetypePassword}
                  setPasswordError={setPasswordError}
                  setForcePassword={setForcePassword}
                  forcePassword={forcePassword}
                  sequentialError={sequentialError}
                  setSequentialError={setSequentialError}
                  showWarning
                  isRequired
                  resetPasswordSection={resetPasswordSection}
                  setResetPasswordSection={setResetPasswordSection}
                />
              </Grid>
              <Box className={classes.doceboEditButtonsWrapper}>
                <Button variant="outlined" color="primary" onClick={handleCancel}>Cancel</Button>
                <Button color="primary" variant="contained" disabled={isButtonDisabled()} onClick={handleSubmit}>Create User</Button>
              </Box>
            </Box>
          </>
        )}
    </Paper>
  );

  return (
    <>
      {/* {open && (
        <AlertBarWithAction
          variant={variant}
          labelText={labelText}
          actionButtonIcon={<CloseIcon onClick={() => toggleAlertBar(initialAlertBarConfig)} />}
        />
      )} */}
      {
        (showElement) && (
          <AlertReleaseInfo
            progress={progress}
            showElement={showElement}
            progressMessage={'User Creation is in progress'}
            withErrorsMessage={'Failed to create user. Please try again'}
            failedMessage={'Failed to create user. Please try again'}
            successMessage={`User ${`${values.firstname} ${values.lastname}`} has been successfully created.`}
            onViewStatus={onViewStatus}
            setShowElement={setShowElement}
          />
        )
      }
      <Paper className={classes.wrapper}>
        <Box className={classes.promptWrapper}>
          <BreadCrumbHOC list={breadCrumbList} />
          {getUserInfoJsx()}
          {getDoceboDetailsJsx()}

        </Box>
        <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={clearAlertBar} />
      </Paper>
      {openStatusModal ? (
        <ProgressStatusModal
          open={openStatusModal}
          onClose={() => { setOpenStatusModal(false); }}
          progress={progress}
          action={'single_user_creation'}
        />
      ) : null}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </>
  );
};

UserCreation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  userDetails: PropTypes.object.isRequired,
  clearAlertBar: PropTypes.func.isRequired,
  toggleAlertBar: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchClientIds: PropTypes.func.isRequired,
  clearTransactionId: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
};

export default UserCreation;
