import React, { memo } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import UserWarning from '../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  bodyWrapper: {
    flex: 1,
    padding: '0 0 2rem 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  box: {
    marginBottom: '1.75rem',
    padding: '1rem 6rem',
    textAlign: 'center',
  },
  boxHeading: {
    display: 'flex',
    flexDirection: 'row',
    height: '3.5rem',
    alignItems: 'center',
    '& > p': {
      fontWeight: 'bold',
    },
  },
  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    '& > :nth-child(1)': {
      margin: '3rem 0 2rem 0',
    },
  },
  closeButton: {
    alignSelf: 'unset',
    height: '46px',
    position: 'absolute',
    right: 0,
    color: theme.palette.grey[500],
  },
}));

const DeleteUserConfirmationModal = ({
  open, onClose, onDelete,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: classes.wrapper }}
    >
      <Container className={classes.bodyWrapper}>
        <div className={classes.imageWrapper}>
          <img src={UserWarning} alt="User Warning" width={120} height={120} />
          {onClose && (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <DialogContent className={classes.content}>
          <Typography variant="subtitle1" className={classes.heading}>Delete User</Typography>
          <div className={classes.box}>
            <div className={classes.boxHeading}>
              <Typography>
                Deleting users cannot be undone and will be updated across all
                platforms containing the user.
                Are you sure you want to proceed?
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onDelete}>
            Delete
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};

DeleteUserConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default memo(DeleteUserConfirmationModal);
