import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Stack from '@mui/material/Stack';
import FlagIcon from '@mui/icons-material/Flag';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import { MCKINSEY_BLUE, FLAG_RED } from '../../stylesheets/colors';
import MyContext from '../../context';
import { ROLE_SUPERADMIN } from '../../constants';

const useStyles = makeStyles(() => ({
  highlight: {
    color: MCKINSEY_BLUE,
    cursor: 'pointer',
    width: 'fit-content',
  },
  checkboxChecked: {
    opacity: 1,
  },
  flagIcon: {
    color: FLAG_RED,
  },
}));

const UsersTableBody = ({
  tableData, onUserClick, selectedUsers, handleCheckUser, markUnmarkForDeletion, isCpRole,
  deleteUser, isActionDisabled,
}) => {
  const classes = useStyles();
  const { role } = useContext(MyContext);
  const isSuperAdmin = role === ROLE_SUPERADMIN;

  const getUserTableCell = (value) => (
    <TableCell align="left">
      {typeof value === 'string' || value instanceof String ? <Typography>{value || '-'}</Typography> : value}
    </TableCell>
  );

  return (
    <TableBody>
      {tableData?.map((user) => {
        const {
          user_id, firstname, lastname, client_name, user_name, deleted_flag,
        } = user;
        return (
          <TableRow
            key={user_id}
            sx={{ '&:last-child td, &:last-child th': { border: '0' } }}
          >
            <TableCell align="center">
              <Checkbox
                className={classes.checkbox}
                checked={selectedUsers.map((item) => item.user_name).includes(user.user_name)}
                onChange={(e) => handleCheckUser(e, user)}
                name="checkAll"
                color="primary"
                data-testid="selectrow"
                classes={{
                  checked: classes.checkboxChecked,
                }}
              />
            </TableCell>
            {getUserTableCell(
              <Stack direction="row" alignItems="center" gap={1}>
                {deleted_flag && (<FlagIcon className={classes.flagIcon} />)}
                <Typography className={`${isActionDisabled ? '' : classes.highlight}`} onClick={() => { onUserClick(user_id); }}>{firstname || '-'}</Typography>
              </Stack>,
            )}
            {getUserTableCell(lastname)}
            {getUserTableCell(client_name)}
            {getUserTableCell(user_name)}
            <TableCell align="right" data-testid="ThreeDotButtonCell">
              {
                !isCpRole && (
                  <CustomPopover
                    list={[
                      {
                        title: `${deleted_flag ? 'Unmark for Deletion' : 'Mark for Deletion'}`,
                        onClick: () => markUnmarkForDeletion(deleted_flag, user),
                        disabled: isActionDisabled,
                      },
                      ...isSuperAdmin && deleted_flag ? [{ title: 'Delete Users', onClick: () => deleteUser(user), disabled: isActionDisabled }] : [],
                    ]}
                    button={ThreeDotButton}
                    additionalClass={classes.customPopoverList}
                  />
                )
              }

            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

UsersTableBody.propTypes = {
  tableData: PropTypes.array.isRequired,
  onUserClick: PropTypes.func.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  handleCheckUser: PropTypes.func.isRequired,
  markUnmarkForDeletion: PropTypes.func.isRequired,
  isCpRole: PropTypes.bool.isRequired,
  deleteUser: PropTypes.func.isRequired,
  isActionDisabled: PropTypes.bool.isRequired,
};

export default UsersTableBody;
